:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._alignItems-center{align-items:center;}
:root ._gap-1316330207{gap:var(--space-13);}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-1481558183{gap:var(--space-8);}
:root ._justifyContent-center{justify-content:center;}
:root ._gap-1481558245{gap:var(--space-6);}