:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-flex-end{justify-content:flex-end;}
:root ._gap-8px{gap:8px;}
:root ._gap-6px{gap:6px;}
:root ._width-258729712{width:max-content;}
:root ._flexDirection-column{flex-direction:column;}
:root ._position-absolute{position:absolute;}
:root ._gap-1316331168{gap:var(--space-23);}
:root ._pb-14px{padding-bottom:14px;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._width-7037{width:70%;}