:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-1481558307{gap:var(--space-4);}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._pt-1481558307{padding-top:var(--space-4);}
:root ._pb-1481558307{padding-bottom:var(--space-4);}
:root ._pr-1481558183{padding-right:var(--space-8);}
:root ._pl-1481558183{padding-left:var(--space-8);}
:root ._gap-8px{gap:8px;}
:root ._gap-1481558152{gap:var(--space-9);}