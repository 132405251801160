:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._maxWidth-920px{max-width:920px;}
:root ._alignItems-center{align-items:center;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._pointerEvents-auto{pointer-events:auto !important;}
:root ._width-480px{width:480px;}
:root ._pt-1316330362{padding-top:var(--space-18);}
:root ._pr-1316330362{padding-right:var(--space-18);}
:root ._pb-1316330362{padding-bottom:var(--space-18);}
:root ._pl-1316330362{padding-left:var(--space-18);}
:root ._borderTopLeftRadius-1307610091{border-top-left-radius:var(--radius-8);}
:root ._borderTopRightRadius-1307610091{border-top-right-radius:var(--radius-8);}
:root ._borderBottomRightRadius-1307610091{border-bottom-right-radius:var(--radius-8);}
:root ._borderBottomLeftRadius-1307610091{border-bottom-left-radius:var(--radius-8);}
:root ._backgroundColor-568007324{background-color:var(--surface1);}
:root ._maxWidth-10037{max-width:100%;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexGrow-1{flex-grow:1;}
:root ._position-absolute{position:absolute;}
:root ._width-10037{width:100%;}
:root ._justifyContent-center{justify-content:center;}
:root ._bottom-48px{bottom:48px;}
@media (max-height: 800px){:root:root:root:root:root:root:root:root:root:root:root ._display-_midHeight_none{display:none;}}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._cursor-pointer{cursor:pointer;}
:root ._width-500px{width:500px;}