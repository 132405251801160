:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._borderTopLeftRadius-1881200688{border-top-left-radius:var(--radius-10);}
:root ._borderTopRightRadius-1881200688{border-top-right-radius:var(--radius-10);}
:root ._borderBottomRightRadius-1881200688{border-bottom-right-radius:var(--radius-10);}
:root ._borderBottomLeftRadius-1881200688{border-bottom-left-radius:var(--radius-10);}
:root ._bottom-537{bottom:5%;}
:root ._left-0px{left:0px;}
:root ._position-absolute{position:absolute;}
:root ._right-0px{right:0px;}
:root ._top-537{top:5%;}
:root ._backgroundColor-568007262{background-color:var(--surface3);}
:root ._backgroundColor-568007293{background-color:var(--surface2);}