:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-130px{width:130px;}
:root ._flexGrow-0{flex-grow:0;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._gap-10px{gap:10px;}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_10037{width:100%;}}
:root ._flexShrink-0{flex-shrink:0;}
:root ._maxWidth-100vw{max-width:100vw;}
:root ._width-10037{width:100%;}
:root ._gap-1316330114{gap:var(--space-10);}
:root ._pt-1481558400{padding-top:var(--space-1);}
:root ._pr-1316330269{padding-right:var(--space-15);}
:root ._pl-1316330269{padding-left:var(--space-15);}
:root ._pb-40px{padding-bottom:40px;}
@media (max-width: 768px){:root:root:root:root:root ._pr-_lg_1316330238{padding-right:var(--space-14);}}
@media (max-width: 768px){:root:root:root:root:root ._pl-_lg_1316330238{padding-left:var(--space-14);}}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._gap-1316330176{gap:var(--space-12);}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
:root ._height-10037{height:100%;}
:root ._gap-1316330300{gap:var(--space-16);}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_none{display:none;}}
:root ._gap-1481558183{gap:var(--space-8);}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_auto{width:auto;}}
:root ._display-none{display:none;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_flex{display:flex;}}
:root ._borderTopColor-568007262{border-top-color:var(--surface3);}
:root ._borderRightColor-568007262{border-right-color:var(--surface3);}
:root ._borderBottomColor-568007262{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-568007262{border-left-color:var(--surface3);}
:root ._borderTopWidth-0px{border-top-width:0px;}
:root ._borderRightWidth-0px{border-right-width:0px;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderLeftWidth-0px{border-left-width:0px;}
:root ._flexGrow-1{flex-grow:1;}
:root ._height-0px{height:0px;}
:root ._maxHeight-0px{max-height:0px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._alignItems-center{align-items:center;}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_flex-start{align-items:flex-start;}}