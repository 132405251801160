:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexGrow-1{flex-grow:1;}
:root ._pt-1316330331{padding-top:var(--space-17);}
:root ._pr-1316330331{padding-right:var(--space-17);}
:root ._pb-1316330331{padding-bottom:var(--space-17);}
:root ._pl-1316330331{padding-left:var(--space-17);}
:root ._borderTopLeftRadius-1307609967{border-top-left-radius:var(--radius-4);}
:root ._borderTopRightRadius-1307609967{border-top-right-radius:var(--radius-4);}
:root ._borderBottomRightRadius-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._borderBottomLeftRadius-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._backgroundColor-843134974{background-color:var(--neutral1);}