:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._aspectRatio-1{aspect-ratio:1;}
:root ._backgroundColor-843135036{background-color:var(--neutral3);}
:root ._borderTopLeftRadius-1307609998{border-top-left-radius:var(--radius-5);}
:root ._borderTopRightRadius-1307609998{border-top-right-radius:var(--radius-5);}
:root ._borderBottomRightRadius-1307609998{border-bottom-right-radius:var(--radius-5);}
:root ._borderBottomLeftRadius-1307609998{border-bottom-left-radius:var(--radius-5);}
:root ._width-10037{width:100%;}