:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-1481558183{gap:var(--space-8);}
:root ._position-relative{position:relative;}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pr-_sm_1481558245{padding-right:var(--space-6);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pl-_sm_1481558245{padding-left:var(--space-6);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._pb-_sm_1481558183{padding-bottom:var(--space-8);}}
:root ._flexDirection-1448970769{flex-direction:row-reverse;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._zIndex-2{z-index:2;}
:root ._mt-1316330238{margin-top:var(--space-14);}
:root ._width-10037{width:100%;}
:root ._height-10037{height:100%;}