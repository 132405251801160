:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._alignItems-center{align-items:center;}
:root ._gap-1316331199{gap:var(--space-24);}
:root ._width-10037{width:100%;}