:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._backgroundColor-843135036{background-color:var(--neutral3);}
:root ._borderTopLeftRadius-1881200688{border-top-left-radius:var(--radius-10);}
:root ._borderTopRightRadius-1881200688{border-top-right-radius:var(--radius-10);}
:root ._borderBottomRightRadius-1881200688{border-bottom-right-radius:var(--radius-10);}
:root ._borderBottomLeftRadius-1881200688{border-bottom-left-radius:var(--radius-10);}
:root ._height-32px{height:32px;}
:root ._width-32px{width:32px;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._width-10037{width:100%;}