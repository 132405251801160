:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._mb-1481558214{margin-bottom:var(--space-7);}
:root ._mr-1481558245{margin-right:var(--space-6);}
:root ._ml-1481558245{margin-left:var(--space-6);}
:root ._pt-1481558183{padding-top:var(--space-8);}
:root ._pr-1481558183{padding-right:var(--space-8);}
:root ._pb-1481558183{padding-bottom:var(--space-8);}
:root ._pl-1481558183{padding-left:var(--space-8);}
:root ._flexGrow-1{flex-grow:1;}