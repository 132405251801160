:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-10px{gap:10px;}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pb-1316330176{padding-bottom:var(--space-12);}
:root ._pr-1316330114{padding-right:var(--space-10);}
:root ._pl-1316330114{padding-left:var(--space-10);}
:root ._gap-1316330114{gap:var(--space-10);}