:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._alignItems-center{align-items:center;}
:root ._backgroundColor-733175080{background-color:var(--transparent);}
:root ._borderTopLeftRadius-1881200688{border-top-left-radius:var(--radius-10);}
:root ._borderTopRightRadius-1881200688{border-top-right-radius:var(--radius-10);}
:root ._borderBottomRightRadius-1881200688{border-bottom-right-radius:var(--radius-10);}
:root ._borderBottomLeftRadius-1881200688{border-bottom-left-radius:var(--radius-10);}
:root ._overflowX-visible{overflow-x:visible;}
:root ._overflowY-visible{overflow-y:visible;}
:root ._pl-1481558338{padding-left:var(--space-3);}
:root ._position-absolute{position:absolute;}
:root ._pt-1481558338{padding-top:var(--space-3);}