:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._pr-1316330114{padding-right:var(--space-10);}
:root ._pl-1316330114{padding-left:var(--space-10);}
:root ._pt-1481558183{padding-top:var(--space-8);}
:root ._pb-1481558183{padding-bottom:var(--space-8);}
:root ._rowGap-1316330114{row-gap:var(--space-10);}
:root ._backgroundColor-568007324{background-color:var(--surface1);}
:root ._rowGap-1481558183{row-gap:var(--space-8);}
:root ._alignItems-center{align-items:center;}
:root ._width-936162306{width:min-content;}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pr-1481558214{padding-right:var(--space-7);}
:root ._pb-1481558214{padding-bottom:var(--space-7);}
:root ._pl-1481558214{padding-left:var(--space-7);}
:root ._borderTopLeftRadius-1307609998{border-top-left-radius:var(--radius-5);}
:root ._borderTopRightRadius-1307609998{border-top-right-radius:var(--radius-5);}
:root ._borderBottomRightRadius-1307609998{border-bottom-right-radius:var(--radius-5);}
:root ._borderBottomLeftRadius-1307609998{border-bottom-left-radius:var(--radius-5);}
:root ._backgroundColor-692094185{background-color:var(--statusCritical2);}
:root ._rowGap-1481558245{row-gap:var(--space-6);}
:root ._flexDirection-row{flex-direction:row;}
:root ._columnGap-1481558245{column-gap:var(--space-6);}