:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._minWidth-320px{min-width:320px;}
:root ._pt-1316330269{padding-top:var(--space-15);}
:root ._pr-1316330238{padding-right:var(--space-14);}
:root ._pl-1316330238{padding-left:var(--space-14);}
@media (max-width: 640px){:root:root:root:root:root:root ._pt-_md_1481558183{padding-top:var(--space-8);}}
@media (max-width: 640px){:root:root:root:root:root:root ._pr-_md_1481558183{padding-right:var(--space-8);}}
@media (max-width: 640px){:root:root:root:root:root:root ._pb-_md_0px{padding-bottom:0px;}}
@media (max-width: 640px){:root:root:root:root:root:root ._pl-_md_1481558183{padding-left:var(--space-8);}}
:root ._minWidth-0px{min-width:0px;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-1316330114{gap:var(--space-10);}
:root ._flexWrap-wrap{flex-wrap:wrap;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_1481558183{gap:var(--space-8);}}
:root ._gap-1481558245{gap:var(--space-6);}
:root ._height-1611761883{height:var(--size-14);}